import React, { Component, useState,useEffect } from 'react';
import {
  Form,
  Input,
  Radio ,
  Select,
  Row,
  Col,
  Button,
  message,
  InputNumber
} from 'antd';
import Footer from '../../components/layout-components/modal/Footer';
import API from '../../api';
import { connect } from "react-redux"
import {AddClient,EditClient} from '../../actions/clientAction'
// import {ClientDetails,clientPrimaryUser} from './clientform'
const { Option } = Select;
var moment = require('moment-timezone');
export const ClientDetails=[
  {name:'industry_type',
  lable:'Industry Type',
  type:'dropdown',
  rules:[
      {
        required: true,
        message: 'Please select Industry Type!',
      },
    ]
},  
  {name:'company_name',
  lable:'Company Name',
  rules:[
      {
        required: true,
        message: 'Please Enter your company name!',
        whitespace: true,
      },
    ]
},  

{name:'street_address',
  lable:'Street Address',
  rules:[
      {
        required: true,
        message: 'Please Enter your street address!',
        whitespace: true,
      },
    ]
},
{name:'zipcode',
  lable:'Zipcode',
  // type:'number',
  rules:[
    {
      required: true,
      message: 'Please Enter your zipcode!',
    }, 
    // {
    //   type: 'number',
    //   min:0,
    //   max:99,
    //   message: 'Zipcode should be Number only!'
    // },
  ]
},
{name:'city',
  lable:'City',
  disabled:true,
  rules:[
      {
        required: true,
        message: 'Please Enter your city!',
        whitespace: true,
      },
    ]
},
{name:'state',
  lable:'State',
  disabled:true,
  rules:[
      {
        required: true,
        message: 'Please Enter your state!',
        whitespace: true,
      },
    ]
},
{name:'last_ticket_number',
  lable:'Last Ticket Number',
  type:'number',
  rules:[ {
        type: 'number',
        message: 'last ticket number should be Number only!',
      },
    ]
},
{name:'invoice_serial',
lable:'Last Invoice Number',
type:'number',
rules:[ {
      type: 'number',
      message: 'last ticket number should be Number only!',
    },
  ]
},
{name:'invoice_prefix',
lable:'Invoice Prefix',
type:'string',

},
]
const ClientContact=[
  {name:'first_name',
  lable:'First Name',
  rules:[
    {
      required: true,
      message: 'Please Enter your first name!',
      whitespace: true,
    },
  ]
  },
  {name:'last_name',
  lable:'Last Name',
  rules:[
    {
      required: true,
      message: 'Please Enter your last name!',
      whitespace: true,
    },
  ]
  },
  
  
  {name:'email',
  lable:'E-mail',
  rules:[
    {
      type: 'email',
      message: 'The input is not valid E-mail!',
    },
    {
      required: true,
      message: 'Please Enter your E-mail!',
    },
  ]
  },
  {name:'phone',
  lable:'Phone',
  type:'number',
  rules:[
    {
      required: true,
      message: 'Please Enter your phone!',
    },
    {
      type: 'number',
      message: 'phone should be Number only!'
    },
  ]
  },
  
  {name:'phone_other',
  lable:'Other Phone',
  type:'number',
  rules:[
    {
      required: false,
      message: 'Please Enter your other phone!',
    },
    {
      type: 'number',
      message: 'phone should be Number only!'
    },
  ]
  },
]
const clientBillingContact=[
  {name:'billing_first_name',
  lable:'First Name',
  rules:[
    {
      required: true,
      message: 'Please Enter your first name!',
      whitespace: true,
    },
  ]
  },
  {name:'billing_last_name',
  lable:'Last Name',
  rules:[
    {
      required: true,
      message: 'Please Enter your last name!',
      whitespace: true,
    },
  ]
  },
  
  
  {name:'billing_email',
  lable:'E-mail',
  rules:[
    {
      type: 'email',
      message: 'The input is not valid E-mail!',
    },
    {
      required: true,
      message: 'Please Enter your E-mail!',
    },
  ]
  },
  {name:'billing_phone',
  lable:'Phone',
  type:'number',
  rules:[
    {
      required: true,
      message: 'Please Enter your phone!',
    },
    {
      type: 'number',
      message: 'phone should be Number only!'
    },
  ]
  },
  
  {name:'billing_phone_other',
  lable:'Other Phone',
  type:'number',
  rules:[
    {
      required: false,
      message: 'Please Enter your other phone number!',
    },
    {
      type: 'number',
      message: 'phone should be Number only!'
    },
  ]
  },
  
 
]

const clientBillingAddress=[ 
{name:'billing_address',
  lable:'Address',
  rules:[
      {
        required: true,
        message: 'Please Enter your billing address!',
        whitespace: true,
      },
    ]
},
{name:'billing_zipcode',
  lable:'Zipcode',
  // type:'number',
  rules:[
    {
      required: true,
      message: 'Please Enter your zipcode!',
    }
    // , {
    //   type: 'number',
    //   message: 'Zipcode should be Number only!'
    // },
  ]
},
{name:'billing_city',
  lable:'City',
  disabled:true,
  rules:[
      {
        required: true,
        message: 'Please Enter billing city!',
        whitespace: true,
      },
    ]
},
{name:'billing_state',
  lable:'State',
  disabled:true,
  rules:[
      {
        required: true,
        message: 'Please Enter your billing state!',
        whitespace: true,
      },
    ]
},

]


const RegistrationForm = (props) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const [clientDetails,setClient]=useState(ClientDetails)
  const [visible,setVisible]=useState(false)
  const [billingDetails,setBillingDetails]=useState(true)
  const [billingContact,setBillingContact]=useState(true)
  const [industryList,setIndustryList]=useState(null)
  const {addClients,getIndustryList,getClientDetails,EditClients,getZipData}=API;
useEffect(()=>{
  getIndustryList().then((res)=>{
    if(res.data.success){
      setIndustryList(res.data.industryList)
    }
  }).catch(()=>{

  })
},[])
  useEffect(()=>{
    if(props.title==="Edit Client"&&props.client_id!==undefined&&visible){
    getClientDetails(props.client_id).then((res)=>{
      const _data=res.data.data;
      form.setFieldsValue((_data))
      const contact={..._data,
        zipcode:parseInt(_data.zipcode),
        phone:parseInt(_data.phone),
        phone_number:parseInt(_data.phone_number),
        phone_other:_data.phone_other?parseInt(_data.phone_other):null,
        billing_phone:parseInt(_data.billing_phone),
        billing_phone_other:_data.billing_phone_other?parseInt(_data.billing_phone_other):null,
        billing_zipcode:parseInt(_data.billing_zipcode)
      };
      form.setFieldsValue((contact))
      if(contact.street_address === contact.billing_address && contact.zipcode === contact.billing_zipcode){
        setBillingDetails(true)
      }else{
        setBillingDetails(false)
      }
      if(contact.billing_first_name === contact.first_name && contact.billing_last_name === contact.last_name && contact.billing_email === contact.email && contact.phone === contact.billing_phone){
        setBillingContact(true)
      }else{
        setBillingContact(false)
      }
    })
  }
  },[props.client_id,props.title,visible])
  const getZip=(e)=>{
    const id= e.target.id
    const value=e.target.value
    if((id==="register_zipcode"||id==='register_billing_zipcode')&&value){
    getZipData(value).then((res)=>{
      const data=res.data.data;
      data&&data.map((item)=>{
        if(id==="register_zipcode"){
        form.setFieldsValue(({'state':item.state,'city':item.city,}))}else{
          form.setFieldsValue(({'billing_state':item.state,'billing_city':item.city,}))
        }
      })
    })}
  }
  const resetZip=(e)=>{
    const id=e.target.id
   
    const value=e.target.value
    if(id==="register_zipcode"){
      form.setFieldsValue(({'state':'','city':''}))
    }
    if(id==='register_billing_zipcode'){
      form.setFieldsValue(({'billing_state':'','billing_city':''}))
    }
  }
  const onFinish = values => {
   
    values={...values,'zipcode':values.zipcode.toString(),
    phone:values.phone.toString(),
    phone_other:values.phone_other ?values.phone_other.toString():'',logo:'',
    billing_phone_other:values.billing_phone_other?values.billing_phone_other.toString():'',
    billing_phone:values.billing_phone?values.billing_phone.toString():'',
    billing_zipcode:values.billing_zipcode?values.billing_zipcode.toString():'',
    last_ticket_number:values.last_ticket_number ? values.last_ticket_number:0
  }

      let v={...values}
      if(billingDetails){
        values={...values,
          billing_address:v.street_address,
          billing_zipcode:v.zipcode,
          billing_city:v.city,
          billing_state:v.state}
      } 
      if(billingContact){
        values={...values,  
          billing_first_name:v.first_name,
          billing_last_name:v.last_name,
          billing_email:v.email,
          billing_phone:v.phone.toString(),
          billing_phone_other:v.phone_other ?v.phone_other.toString():''}
      }     

      Object.keys(values).map(function(key, index) {
        if(values[key]===""){
           delete values[key]
        }
      });


      if(props.title==="Edit Client"){
      const data={...values,'id':props.client_id}
        props.EditClient(data,(res) =>{
          if(res){
            setVisible(false)
          form.resetFields()
          message.success({ content: `Client Edited`, duration: 2 });
        }else{
          message.error({ content: `Client Edit Fail`, duration: 2 });
          }
        })
      }else{
        props.AddClient(values,(res) =>{
          if(res){
            setVisible(false)
          form.resetFields()
          message.success({ content: `Client Added`, duration: 2 });
        }else{
          message.error({ content: `Client Add Fail`, duration: 2 });
          }
        })
      }
  
  };
  
  const handleBillingAddress=(e)=>{
    setBillingDetails(e.target.value)
  }
  const handleBillingContact=(e)=>{
    setBillingContact(e.target.value)
  }

  return (
    <Footer setVisible={(e)=>setVisible(e)} visible={visible} Button={props.Button} title={props.title}
    submit={<Form.Item>
      <Button type="primary" htmlType="submit" form={props.client_id?`editClient${props.client_id.toString()}`:'newClient'}>
        Submit
      </Button>
    </Form.Item>}
    
    >

    <Form
      form={form}
      id={props.client_id?`editClient${props.client_id.toString()}`:'newClient'}
      name="register"
      onBlur={(e)=>getZip(e)}
      onChange={(e)=>resetZip(e)}
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >
     <Row gutter={24}>
     {clientDetails&&clientDetails.map((form)=><Col span={8}>
      
      <Form.Item
      // disabled 
      labelCol={{span:24}} wrapperCol={{span:24}}
        name={form.name}
        label={
          <span style={{textTransform: 'uppercase'}}>
           {form.lable}
          </span>
        }
        
        initialValue={form.initialValue?form.initialValue:form.lable == 'Last Ticket Number'||form.lable == 'Last Invoice Number'?0:''}
        // initialValue={form.initialValue?form.initialValue:''}
        rules={form.rules}
        
      >
     {form.type==="number"?<InputNumber style={{width:'100%'}}/>:
     form.type==="dropdown"?
<Select
                    
                        virtual={false}
                          allowClear={false}
                          placeholder={`Industry Type`}
                        >
                         {industryList&&industryList.map((item)=>(
                          <Option value={item.param_value.toString()}>
                                {item.param_description}
                              </Option>
                         ))
                              
                            }
                        </Select>
     :<Input disabled={form.disabled}/>}
      </Form.Item>
      </Col>)}
  
     </Row>
      <Radio.Group onChange={(e)=>handleBillingAddress(e)} value={billingDetails}>
      <div style={{display:'flex',flexDirection:'column'}}>    
       <b style={{fontStyle: 'italic',marginRight:20}} >BILL TO ADDRESS</b>
     <span><b style={{fontStyle: 'italic',marginRight:20}} >SAME AS CLIENT ADDRESS</b>
      
       {' '} <Radio value={true} style={{fontStyle: 'italic'}}>YES</Radio>
        <Radio value={false} style={{fontStyle: 'italic'}}>NO</Radio>
        </span>
        </div>
      </Radio.Group>
      <br/>
      <br />
      {!billingDetails&&
      <>
      {/* <Divider/>
      <h4>Client Billing Details</h4>
      <Divider/> */}
      
     <Row gutter={24}>
     {clientBillingAddress&&clientBillingAddress.map((form)=><Col span={8}>
      <Form.Item
       labelCol={{span:24}} wrapperCol={{span:24}}
        name={form.name}
        label={
          <span style={{textTransform: 'uppercase'}}>
           {form.lable}
          </span>
        }
        initialValue={form.initialValue?form.initialValue:''}
        // initialValue={form.initialValue?form.initialValue:''}
        rules={form.rules}
      >
        {form.type==="number"?<InputNumber style={{width:'100%'}}/>:<Input disabled={form.disabled} />}
      </Form.Item>
      </Col>)}



     </Row > </>}

     <Row gutter={24}>
     {ClientContact&&ClientContact.map((form)=><Col span={8}>
      <Form.Item
       labelCol={{span:24}} wrapperCol={{span:24}}
        name={form.name}
        label={
          <span style={{textTransform: 'uppercase'}}>
           {form.lable}
          </span>
        }
        initialValue={form.initialValue?form.initialValue:''}
        // initialValue={form.initialValue?form.initialValue:''}
        rules={form.rules}
      >
        {form.type==="number"?<InputNumber style={{width:'100%'}}/>:<Input disabled={form.disabled} />}
      </Form.Item>
      </Col>)}
     </Row >


      
     <Radio.Group onChange={(e)=>handleBillingContact(e)} value={billingContact}>

     <div style={{display:'flex',flexDirection:'column'}}>    
       <b style={{fontStyle: 'italic',marginRight:20}} >BILL TO CONTACT</b>
     <span><b style={{fontStyle: 'italic',marginRight:20}} >SAME AS CLIENT CONTACT</b>
      
       {' '} <Radio value={true} style={{fontStyle: 'italic'}}>YES</Radio>
        <Radio value={false} style={{fontStyle: 'italic'}}>NO</Radio>
        </span>
        </div>
      </Radio.Group>
      <br/>
      <br />
      {!billingContact&&
      <>  
     <Row gutter={24}>
     {clientBillingContact&&clientBillingContact.map((form)=><Col span={8}>
      <Form.Item
       labelCol={{span:24}} wrapperCol={{span:24}}
        name={form.name}
        label={
          <span style={{textTransform: 'uppercase'}}>
           {form.lable}
          </span>
        }
        initialValue={form.initialValue?form.initialValue:''}
        // initialValue={form.initialValue?form.initialValue:''}
        rules={form.rules}
      >
        {form.type==="number"?<InputNumber style={{width:'100%'}}/>:<Input disabled={form.disabled} />}
      </Form.Item>
      </Col>)}
     </Row > </>}
    </Form></Footer>
  );
};

export class Register extends Component {
  render() {
    return (
      <RegistrationForm {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
	loading:state.user.loading,
	user_status:state.user.user_status
  })
  
  export default connect(mapStateToProps, { AddClient,EditClient })(Register)

